import React from 'react';
import { withStyles, withTheme, Input } from '@material-ui/core';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import StyledModal from 'Components/Shared/Modal/StyledModal';
import Buttons from 'Components/Shared/Modal/StyledModal.Buttons';
import { strings } from 'Constants/Categorization/Strings';
import { commonStrings } from 'Constants/CommonStrings';
import { connect } from 'react-redux';
import { periodIdSelector } from 'Store/Areas/Period/PeriodSelectors';
import { categoriseItems, resetCategorisationCreation } from 'Store/Areas/Rule/CategorisationActions';
import {
  categorisationRulePropertiesSelector,
  categorisationTypeSelector,
  contraShelfLineItemIdsSelector,
  categorizationTypeSelector,
} from 'Store/Areas/Rule/CategorisationSelectors';
import styles from './LineItemsCategorisationReasonModal.styles';

class LineItemsCategorisationReasonModal extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      categorisationReason: '',
      isCategorisationReasonEmpty: false,
      categorisationAddDisclosure: '',
    };
  }
  setCategorisationReason = ({ target: { value } }) => {
    this.setState({ categorisationReason: value, isCategorisationReasonEmpty: value === '' });
  }
  setCategorisationAddDisclosure = ({ target: { value } }) => {
    this.setState({ categorisationAddDisclosure: value });
  }
  handleSubmit = () => {
    if (this.state.categorisationReason === '') {
      this.setState({ isCategorisationReasonEmpty: true });
      return;
    }
    const {
      dispatch,
      ruleProperties,
      categories,
      periodId,
      categoryType,
      searchTerm,
      contraShelfLineItemIds,
      categorizationType,
    } = this.props;
    const { categorisationReason, categorisationAddDisclosure } = this.state;
    dispatch(categoriseItems(
      periodId,
      categories,
      categoryType,
      ruleProperties,
      searchTerm,
      categorisationReason,
      categorisationAddDisclosure,
      contraShelfLineItemIds,
      categorizationType,
    ));
    this.setState({ categorisationReason: '', isCategorisationReasonEmpty: false, categorisationAddDisclosure: '' });
  }

  handleCancel = () => {
    const { dispatch } = this.props;
    this.setState({ categorisationReason: '', isCategorisationReasonEmpty: false, categorisationAddDisclosure: '' });
    dispatch(resetCategorisationCreation());
  }

  render() {
    const { classes, open, categories } = this.props;
    const categoriesNames = categories.map((category) => {
      return category.categoryName;
    }).join(', ');

    if (!open) {
      return null;
    }

    return (
      <StyledModal open={open}>
        <div className={classes.root}>
          <div className={classes.header}>
            <div className={classes.note}>
              <b>{strings.lineItemsCategorisationReasonModalTitle(categoriesNames)}</b>
              <br />
              {strings.lineItemsCategorisationReasonModalDescription}
            </div>
            <div className={classes.textBox}>
              <Input
                className={classes.input}
                classes={{ input: classes.textInput }}
                id="categorisationReason"
                value={this.state.categorisationReason}
                placeholder={strings.lineItemsCategorisationReasonPlaceholder}
                onChange={this.setCategorisationReason}
              />
            </div>
            <If condition={this.state.isCategorisationReasonEmpty}>
              <div>
                <span className={classes.validationError}>
                  {strings.lineItemsCategorisationReasonValidationMessage}
                </span>
              </div>
            </If>
            <div className={classes.textBox}>
              <Input
                className={classes.input}
                classes={{ input: classes.textInput }}
                id="AdditionalDisclosure"
                value={this.state.categorisationAddDisclosure}
                placeholder={strings.lineItemsCategorisationAddDisclosurePlaceholder}
                onChange={this.setCategorisationAddDisclosure}
              />
            </div>
          </div>
          <Buttons
            className={classes.buttons}
            isSubmitting={false}
            onAccept={this.handleSubmit}
            onCancel={this.handleCancel}
            acceptText={commonStrings.proceed}
            cancelText={commonStrings.cancel}
          />
        </div>
      </StyledModal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    categories: state.rules.categorisation.categories,
    periodId: periodIdSelector(state),
    open: state.rules.categorisation.displayLineItemsCategorisationReasonModal,
    ruleProperties: categorisationRulePropertiesSelector(state),
    categoryType: categorisationTypeSelector(state),
    searchTerm: state.rules.categorisation.searchTerm,
    contraShelfLineItemIds: contraShelfLineItemIdsSelector(state),
    categorizationType: categorizationTypeSelector(state),
  };
};

LineItemsCategorisationReasonModal.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  open: PropTypes.bool.isRequired,
  categories: PropTypes.arrayOf(PropTypes.shape({
    categoryId: PropTypes.number,
    name: PropTypes.string,
    percentage: PropTypes.number,
    color: PropTypes.string,
  })).isRequired,
  periodId: PropTypes.number.isRequired,
  ruleProperties: PropTypes.object.isRequired, // eslint-disable-line
  dispatch: PropTypes.func.isRequired,
  categoryType: PropTypes.string.isRequired,
  searchTerm: PropTypes.string.isRequired,
  contraShelfLineItemIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  categorizationType: PropTypes.number.isRequired,
};

export default compose(
  withStyles(styles),
  withTheme(),
  connect(mapStateToProps),
)(LineItemsCategorisationReasonModal);
