import { takeLatest, put } from 'redux-saga/effects';
import { REQUEST_EDIT_RULE_NOTE_ADD_DISCLOSURE, requestEditRuleNoteAddDisclosureSuccess } from 'Store/Areas/Rule/EditRuleNoteAddDisclosureActions';
import { Post } from 'Store/Api/CallApi';
import { RULE_REQUEST_EDIT_RULE_NOTE_ADD_DISCLOSURE } from 'Store/Api/ApiEndpoints';
import handleStatusCode from 'Store/Api/HandleStatusCode';

function* requestEditRuleNoteAddDisclosureSaga(action) {
  const response = yield Post(RULE_REQUEST_EDIT_RULE_NOTE_ADD_DISCLOSURE, action.payload);
  if (response.ok) {
    const data = yield response.json();
    yield put(requestEditRuleNoteAddDisclosureSuccess(data, action.index));
  } else {
    yield handleStatusCode(response.status);
  }
}

export function* editRuleNoteAddDisclosureWatcher() {
  yield takeLatest(REQUEST_EDIT_RULE_NOTE_ADD_DISCLOSURE, requestEditRuleNoteAddDisclosureSaga);
}
