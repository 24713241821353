export const OPEN_EDIT_RULE_NOTE_ADD_DISCLOSURE = 'EDITRULENOTEADDDISCLOSURE/OPEN_EDIT_RULE_NOTE_ADD_DISCLOSURE';
export const CLOSE_EDIT_RULE_NOTE_ADD_DISCLOSURE = 'EDITRULENOTEADDDISCLOSURE/CLOSE_EDIT_RULE_NOTE_ADD_DISCLOSURE';
export const REQUEST_EDIT_RULE_NOTE_ADD_DISCLOSURE = 'EDITRULENOTEADDDISCLOSURE/REQUEST_EDIT_RULE_NOTE_ADD_DISCLOSURE';
export const SUCCESS_EDIT_RULE_NOTE_ADD_DISCLOSURE = 'EDITRULENOTEADDDISCLOSURE/SUCCESS_EDIT_RULE_NOTE_ADD_DISCLOSURE';

export function openEditRuleNoteAddDisclosure() {
  return {
    type: OPEN_EDIT_RULE_NOTE_ADD_DISCLOSURE,
    open: true,
  };
}

export function closeEditRuleNoteAddDisclosure() {
  return {
    type: CLOSE_EDIT_RULE_NOTE_ADD_DISCLOSURE,
    open: false,
    loading: false,
  };
}

export function requestEditRuleNoteAddDisclosure(periodId, ruleIds, noteText, index) {
  return {
    type: REQUEST_EDIT_RULE_NOTE_ADD_DISCLOSURE,
    loading: true,
    index,
    payload: {
      periodId: periodId,
      ruleIds: ruleIds,
      noteAdditionalDisclosure: noteText,
    },
  };
}

export function requestEditRuleNoteAddDisclosureSuccess(data, index) {
  return {
    type: SUCCESS_EDIT_RULE_NOTE_ADD_DISCLOSURE,
    open: false,
    loading: false,
    noteAdditionalDisclosure: data,
    index,
  };
}