import {
  OPEN_EDIT_RULE_NOTE_ADD_DISCLOSURE,
  CLOSE_EDIT_RULE_NOTE_ADD_DISCLOSURE,
  REQUEST_EDIT_RULE_NOTE_ADD_DISCLOSURE,
  SUCCESS_EDIT_RULE_NOTE_ADD_DISCLOSURE,
} from 'Store/Areas/Rule/EditRuleNoteAddDisclosureActions';

const initialState = {
  loading: false,
  open: false,
  ruleNoteAdditionalDisclosure: '',
};

export default function editRuleNoteAddDisclosureReducer(state = initialState, action) {
  switch (action.type) {
    case OPEN_EDIT_RULE_NOTE_ADD_DISCLOSURE:
    case CLOSE_EDIT_RULE_NOTE_ADD_DISCLOSURE:
    case SUCCESS_EDIT_RULE_NOTE_ADD_DISCLOSURE:
      return Object.assign({}, state, {
        loading: action.loading,
        open: action.open,
      });
    case REQUEST_EDIT_RULE_NOTE_ADD_DISCLOSURE:
      return Object.assign({}, state, {
        loading: action.loading,
      });
    default:
      return state;
  }
}

