import { combineReducers } from 'redux';
import treePickerReducer from './Areas/Projects/TreePickerReducer';
import uploadReducer from './Areas/Import/UploadReducer';
import processReducer from './Areas/Import/ProcessReducer';
import permissionsReducer from './Areas/User/PermissionsReducer';
import getUsersReducer from './Areas/Setup/Admin/GetUsersReducer';
import addUsersReducer from './Areas/Setup/Admin/AddUsersReducer';
import groupReducer from './Areas/Setup/Admin/GroupReducer';
import createPeriodReducer from './Areas/Projects/CreatePeriodReducer';
import templateReducer from './Areas/Projects/TemplatesReducer';
import projectReducer from './Areas/Setup/Admin/ProjectReducer';
import entityReducer from './Areas/Setup/Admin/EntityReducer';
import toastNotificationReducer from './Areas/App/ToastNotificationReducer';
import adminTreePickerReducer from './Areas/Setup/Admin/AdminTreePickerReducer';
import userTreePickerReducer from './Areas/Projects/UserTreePickerReducer';
import removeAccessReducer from './Areas/Setup/Admin/RemoveAccessReducer';
import periodReducer from './Areas/Period/PeriodReducer';
import menuModeReducer from './Areas/App/MenuModeReducer';
import categoryReducer from './Areas/Categorisation/CategoryReducer';
import keywordReducer from './Areas/Categorisation/KeywordReducer';
import comboKeywordReducer from './Areas/Categorisation/ComboKeywordReducer';
import deleteCategoryReducer from './Areas/Categorisation/DeleteCategoryReducer';
import reviewCategoryReducer from './Areas/Categorisation/ReviewCategoryReducer';
import categoryRulesReducer from './Areas/Rule/CategoryRulesReducer';
import categoryRulesFilterReducer from './Areas/Rule/CategoryRulesFilterReducer';
import conflictReducer from './Areas/Conflict/ConflictReducer';
import processingReducer from './Areas/Categorisation/ProcessingReducer';
import inspectRuleReducer from './Areas/Rule/InspectRuleReducer';
import addTagReducer from './Areas/Import/AddTagReducer';
import deleteTagReducer from './Areas/Import/DeleteTagReducer';
import mapTagReducer from './Areas/Import/MapTagReducer';
import categorisationReducer from './Areas/Rule/CategorisationReducer';
import rulePriorityReducer from './Areas/Rule/RulePriorityReducer';
import ruleBuilderReducer from './Areas/Rule/RuleBuilderReducer';
import previewReducer from './Areas/Import/PreviewReducer';
import keywordSearchReducer from './Areas/Categorisation/KeywordSearchReducer';
import deleteRuleReducer from './Areas/Rule/DeleteRuleReducer';
import editRuleNoteReducer from './Areas/Rule/EditRuleNoteReducer';
import editRuleNoteAddDisclosureReducer from './Areas/Rule/EditRuleNoteAddDisclosureReducer';
import editRuleReducer from './Areas/Rule/EditRuleReducer';
import createRuleReducer from './Areas/Rule/CreateRuleReducer';
import lineItemReducer from './Areas/Categorisation/LineItemReducer';
import apportionablesReducer from './Areas/Apportionables/ApportionablesReducer';
import lineItemValueReducer from './Areas/Import/LineItemValueReducer';
import periodImportDetailsReducer from './Areas/Import/PeriodImportDetailsReducer';
import distributionGraphReducer from './Areas/Import/DistributionGraphReducer';
import errorPageReducer from './Areas/App/ErrorPageReducer';
import lineItemMetadataReducer from './Areas/Review/LineItemMetadataReducer';
import importSummaryReducer from './Areas/Import/ImportSummaryReducer';
import comparePeriodReducer from './Areas/Review/ComparePeriodReducer';
import methodOfCategorisationReducer from './Areas/Review/MethodOfCategorisationReducer';
import uploadTemplateReducer from './Areas/Template/UploadTemplateReducer';
import bulkUserUploadReducer from './Areas/BulkUpload/BulkUserUploadReducer';
import bulkGroupEntityProjectUploadReducer from './Areas/BulkUpload/BulkGroupEntityProjectUploadReducer';
import contrasReducer from './Areas/Import/ContrasReducer';
import importFailedRulesReducer from './Areas/Import/ImportFailedRulesReducer';
import exportReducer from './Areas/Export/ExportReducer';
import supportingDocumentMappingReducer from './Areas/Import/SupportingDocumentMappingReducer';
import siteLoaderReducer from './Areas/App/SiteLoaderReducer';
import fileUploadReducer from './Areas/FileUpload/FileUploadReducer';
import referenceListReducer from './Areas/Categorisation/ReferenceListReducer';
import signalrSideEffectReducer from './Areas/App/SignalrSideEffectReducer';
import locationReducer from './Areas/Router/LocationReducer';
import configReducer from './Areas/App/ConfigReducer';
import machineLearningReducer from './Areas/MachineLearning/MachineLearningReducer';
import externalReviewReducer from './Areas/Export/ExternalReviewReducer';
import exportPivotTableReducer from './Areas/Export/ExportPivotTableReducer';
import settingsReducer from './Areas/Settings/SettingsReducer';
import changePeriodStatusReducer from './Areas/Projects/ChangePeriodStatusReducer';
import apportionablesModalReducer from './Areas/Apportionables/ApportionablesModalReducer';
import registerReducer from './Areas/Register/RegisterReducer';

/*
naming convention for state properties and their reducers: property : propertyReducer
Reducers should be split into project areas using nested combineReducers({})
*/

export default combineReducers({
  projects: combineReducers({
    treePicker: treePickerReducer,
    createPeriod: createPeriodReducer,
    templates: templateReducer,
    userTreePicker: userTreePickerReducer,
    changePeriodStatus: changePeriodStatusReducer,
  }),
  periods: combineReducers({
    period: periodReducer,
    periodConflicts: conflictReducer,
  }),
  import: combineReducers({
    upload: uploadReducer,
    process: processReducer,
    preview: previewReducer,
    tagMapping: combineReducers({
      addTag: addTagReducer,
      deleteTag: deleteTagReducer,
      mapTag: mapTagReducer,
    }),
    machineLearning: combineReducers({
      lineItemValue: lineItemValueReducer,
      distributionGraph: distributionGraphReducer,
    }),
    importSummary: importSummaryReducer,
    contras: contrasReducer,
    importFailedRules: importFailedRulesReducer,
    supportingDocumentMapping: supportingDocumentMappingReducer,
    periodImportDetails: periodImportDetailsReducer,
  }),
  user: combineReducers({
    permissions: permissionsReducer,
  }),
  setup: combineReducers({
    admin: combineReducers({
      getUsers: getUsersReducer,
      addUsers: addUsersReducer,
      group: groupReducer,
      project: projectReducer,
      entity: entityReducer,
      adminTreePicker: adminTreePickerReducer,
      removeAccess: removeAccessReducer,
    }),
  }),
  templateUpload: uploadTemplateReducer,
  bulkUserUpload: bulkUserUploadReducer,
  bulkGroupEntityProjectUpload: bulkGroupEntityProjectUploadReducer,
  categorisation: combineReducers({
    apportionables: apportionablesReducer,
    lineItems: lineItemReducer,
    category: categoryReducer,
    processing: processingReducer,
    deleteCategory: deleteCategoryReducer,
    keywords: keywordReducer,
    comboKeywords: comboKeywordReducer,
    reviewCategory: reviewCategoryReducer,
    keywordSearch: keywordSearchReducer,
    referenceLists: referenceListReducer,
  }),
  rules: combineReducers({
    categoryRules: categoryRulesReducer,
    categoryRulesFilter: categoryRulesFilterReducer,
    inspectRule: inspectRuleReducer,
    ruleBuilder: ruleBuilderReducer,
    deleteRule: deleteRuleReducer,
    editRule: editRuleReducer,
    createRule: createRuleReducer,
    editRuleNote: editRuleNoteReducer,
    editRuleNoteAddDisclosure: editRuleNoteAddDisclosureReducer,
    categorisation: categorisationReducer,
    rulePriority: rulePriorityReducer,
  }),
  app: combineReducers({
    siteLoader: siteLoaderReducer,
    toastNotification: toastNotificationReducer,
    menuMode: menuModeReducer,
    errorPage: errorPageReducer,
    fileUpload: fileUploadReducer,
    signalrSideEffects: signalrSideEffectReducer,
    location: locationReducer,
    config: configReducer,
  }),
  review: combineReducers({
    lineItemMetadata: lineItemMetadataReducer,
    comparePeriod: comparePeriodReducer,
    methodOfCategorisation: methodOfCategorisationReducer,
  }),
  export: combineReducers({
    export: exportReducer,
    externalReview: externalReviewReducer,
    exportPivotTable: exportPivotTableReducer,
  }),
  machineLearning: machineLearningReducer,
  settings: settingsReducer,
  apportionables: combineReducers({
    apportionables: apportionablesReducer,
    apportionablesModal: apportionablesModalReducer,
  }),
  register: registerReducer,
});
