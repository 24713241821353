import React from 'react';
import { withStyles, withTheme, TextField } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import Modal from 'Components/Shared/Modal/StyledModal';
import StyledModalButtons from 'Components/Shared/Modal/StyledModal.Buttons';
import { commonStrings } from 'Constants/CommonStrings';
import { strings } from 'Constants/Categorization/Strings';
import { closeEditRuleNoteAddDisclosure, requestEditRuleNoteAddDisclosure } from 'Store/Areas/Rule/EditRuleNoteAddDisclosureActions';
import { styles } from './EditRuleNoteAddDisclosureModal.styles';

class EditRuleNoteAddDisclosureModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      note: props.ruleAddDisclosureNote,
    };
  }

  onCancel() {
    this.props.dispatch(closeEditRuleNoteAddDisclosure());
  }

  onSubmit() {
    const {
      dispatch, periodId, ruleId, categoryRules,
    } = this.props;
    const index = categoryRules.rules.findIndex(x => x.id === ruleId);
    dispatch(requestEditRuleNoteAddDisclosure(periodId, [ruleId], this.state.note, index));
  }

  onChange = (event) => {
    this.setState({
      note: event.target.value,
    });
  }

  render() {
    const {
      classes,
      editRuleNoteAddDisclosure,
      ruleId,
    } = this.props;

    const { note } = this.state;

    if (!editRuleNoteAddDisclosure.open || !ruleId) {
      return null;
    }

    return (
      <Modal open isSubmitting={editRuleNoteAddDisclosure.loading}>
        <div className={classes.content}>
          <div className={classes.title}>
            {strings.editRuleNoteAddDisclosureTitle}
          </div>
          <div>
            <TextField
              className={classes.textField}
              placeholder={strings.customRuleNotePlaceholder}
              value={note}
              margin="dense"
              InputProps={{
                classes: {
                  input: classes.textSize,
                },
                inputProps: {
                  maxLength: '255',
                },
              }}
              variant="outlined"
              onChange={this.onChange}
            />
          </div>
        </div>
        <StyledModalButtons
          onCancel={() => this.onCancel()}
          onAccept={() => this.onSubmit()}
          acceptText={strings.editRuleNoteAddDisclosureButton}
          cancelText={commonStrings.cancel}
          isSubmitting={editRuleNoteAddDisclosure.loading}
        />
      </Modal>
    );
  }
}

EditRuleNoteAddDisclosureModal.defaultProps = {
  ruleAddDisclosureNote: '',
};

EditRuleNoteAddDisclosureModal.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  dispatch: PropTypes.func.isRequired,
  periodId: PropTypes.number.isRequired,
  ruleId: PropTypes.number.isRequired,
  ruleAddDisclosureNote: PropTypes.string,
  editRuleNoteAddDisclosure: PropTypes.shape({
    open: PropTypes.bool,
    loading: PropTypes.bool,
  }).isRequired,
  categoryRules: PropTypes.shape({
    name: PropTypes.string,
    rules: PropTypes.arrayOf(PropTypes.shape({
      keywordRule: PropTypes.string,
      addedBy: PropTypes.string,
      dateCreated: PropTypes.string,
      value: PropTypes.number,
      priority: PropTypes.number,
    })),
    morePagesAvailable: PropTypes.bool,
    loading: PropTypes.bool,
    totalSum: PropTypes.number,
    currentPageNumber: PropTypes.number,
  }).isRequired,
};

function mapStateToProps(state) {
  const { inspectedRule } = state.rules.inspectRule;
  return {
    ruleId: inspectedRule ? inspectedRule.id : -1,
    periodId: inspectedRule ? inspectedRule.periodId : -1,
    editRuleNoteAddDisclosure: state.rules.editRuleNoteAddDisclosure,
    categoryRules: state.rules.categoryRules,
  };
}

export default compose(
  withStyles(styles),
  withTheme(),
  connect(mapStateToProps),
)(EditRuleNoteAddDisclosureModal);