export const strings = {
  title: 'Inspect rule',
  headerRule: 'Rule',
  headerApportionment: 'Apportionment',
  headerCreatedDate: 'Created date',
  headerCreatedBy: 'Created by',
  headerPriority: 'Priority',
  headerValue: 'Value',
  headerNote: 'Note',
  headerAddDisclosureNote: 'Rule Note Additional Disclosure',
  headerEditButton: 'Edit rule',
  headerDeleteButton: 'Delete rule',
  headerEditNoteButton: 'Edit note',
  headerEditNoteAddDisclosureButton: 'Edit note Additional Disclosure',
  headerEditPriorityButton: 'Edit priority',
};
